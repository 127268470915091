import React, { useState, useEffect } from 'react';
import { useUser } from '../context/UserContext';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { AiOutlineMail, AiOutlineUser, AiOutlineHome, AiOutlinePhone, AiOutlineBulb, AiOutlineLock } from 'react-icons/ai';

const UserProfile = () => {
    const { currentUser, updateUserProfile, updateUserPassword, profileData } = useUser(); // Extract profileData from context
    const [pseudo, setPseudo] = useState('');
    const [adresse, setAdresse] = useState('');
    const [password, setPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState(''); // To capture current password
    const [error, setError] = useState(''); // To display any error messages
    const navigate = useNavigate();
    const [phone, setPhone] = useState(''); // Pour le numéro de téléphone
    const [job, setJob] = useState(''); // Pour le travail ou la profession
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (!currentUser) {
            navigate("/");
        } else {
            // Utilisez l'état profileData pour mettre à jour les champs
            setPseudo(profileData.pseudo || '');
            setAdresse(profileData.adresse || '');
            setPhone(profileData.phone || '');
            setJob(profileData.job || '');
        }
    }, [currentUser, profileData, navigate]);


    const reauthenticate = async (currentPassword) => {
        const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);
        try {
            await reauthenticateWithCredential(currentUser, credential);
        } catch (error) {
            setError('Your current password is incorrect.');
            throw error;
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setError('');

        const profileData = {
            pseudo, // Ceci est déjà inclus
            adresse,
            phone, // Assurez-vous que ce champ est correctement lié dans Firestore
            job, // De même pour ce champ
        };

        try {
            if (password) {
                await reauthenticate(currentPassword); // Re-authenticate before updating the password
                await updateUserPassword(password);
                setPassword(''); // Clear password field after update
            }
            await updateUserProfile(profileData);
            setSuccessMessage('Profil mis à jour avec succès!');
        } catch (error) {
            console.error('Error updating profile:', error);
            setError(error.message || 'Une erreur est survenue lors de la mise à jour du profil.');
        }
    };


    if (!currentUser) return null;

    return (
        <Container className="user-profile-container">
            <Row>
                <Col md={6} className="mx-auto mb-5">
                    <div className="user-profile-form">
                        <h2 className="text-center my-4">
                            <AiOutlineUser style={{ color: '#007bff', marginRight: '8px' }} />
                            Profil - {pseudo || currentUser?.email}
                        </h2>


                        {successMessage && <Alert variant="success">{successMessage}</Alert>}
                        {error && <Alert variant="danger">{error}</Alert>}                        <Form onSubmit={handleUpdate}>
                            <Form.Group className="mb-3">
                                <Form.Label className="form-label"><AiOutlineMail style={{ color: '#007bff', marginRight: '8px' }} />Email</Form.Label>
                                <Form.Control type="email" value={currentUser.email} disabled />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="form-label"><AiOutlineUser style={{ color: '#007bff', marginRight: '8px' }} />Pseudo</Form.Label>
                                <Form.Control type="text" value={pseudo} onChange={(e) => setPseudo(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="form-label"><AiOutlineHome style={{ color: '#007bff', marginRight: '8px' }} />Adresse</Form.Label>
                                <Form.Control type="text" value={adresse} onChange={(e) => setAdresse(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="form-label"><AiOutlinePhone style={{ color: '#007bff', marginRight: '8px' }} />Téléphone</Form.Label>
                                <Form.Control type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="form-label"><AiOutlineBulb style={{ color: '#007bff', marginRight: '8px' }} />Profession</Form.Label>
                                <Form.Control type="text" value={job} onChange={(e) => setJob(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="form-label"><AiOutlineLock style={{ color: '#007bff', marginRight: '8px' }} />Current Password</Form.Label>
                                <Form.Control type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="form-label"><AiOutlineLock style={{ color: '#007bff', marginRight: '8px' }} />New Password</Form.Label>
                                <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </Form.Group>
                            <Button variant="primary" type="submit"><AiOutlineUser style={{ marginRight: '8px' }} />Update Profile</Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    );

};

export default UserProfile;
