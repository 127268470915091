import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const NewListModal = ({ show, handleClose, handleCreateList }) => {
    const [listTitle, setListTitle] = useState('');
    const [listDescription, setListDescription] = useState('');

    const submitList = () => {
        if (!listTitle.trim()) {
            alert('Please provide a title for the list.');
            return;
        }
        handleCreateList({
            title: listTitle,
            description: listDescription,
        });
        setListTitle('');
        setListDescription('');
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Créer une nouvelle liste</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Titre de la liste</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Entrez le titre de la liste"
                            value={listTitle}
                            onChange={(e) => setListTitle(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description de la liste (optionnel)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Entrez une description pour la liste"
                            value={listDescription}
                            onChange={(e) => setListDescription(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Fermer
                </Button>
                <Button variant="primary" onClick={submitList}>
                    Créer la liste
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NewListModal;
