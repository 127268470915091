import React, { useState, useContext } from 'react';
import { auth, db } from '../firebase-config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { Button, Form } from 'react-bootstrap';
import { UserContext } from '../context/UserContext'; // Assurez-vous d'importer le contexte utilisateur

function SignIn({ closeSignInModal }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setUserRole } = useContext(UserContext); // Utiliser le contexte pour définir le rôle

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userDocRef = doc(db, "Users", userCredential.user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        console.log("Document data:", userDocSnap.data());
        // Définir le rôle de l'utilisateur dans le contexte (ou l'état global de l'application)
        setUserRole(userDocSnap.data().role);
        closeSignInModal();
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Form onSubmit={handleLogin}>
      <Form.Group className="mb-3" controlId="signInEmail">
        <Form.Label>Adresse e-mail</Form.Label>
        <Form.Control
          type="email"
          placeholder="Entrez votre e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="signInPassword">
        <Form.Label>Mot de passe</Form.Label>
        <Form.Control
          type="password"
          placeholder="Entrez votre mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </Form.Group>

      <Button variant="primary" type="submit">
        Connexion
      </Button>
    </Form>
  );
}

export default SignIn;
