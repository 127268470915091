import React from 'react';
import { Button } from 'react-bootstrap';
import TaskCard from './TaskCard';
import './ListColumn.css'; // Assurez-vous que le chemin d'importation est correct

const ListColumn = ({ title, tasks, onAddTask, onEditTask, onDeleteTask }) => {
    return (
        <div className="list-column">
            <h4>{title}</h4>
            {tasks.map(task => (
                <TaskCard
                    key={task.id}
                    task={task}
                    onEdit={() => onEditTask(task)}
                    onDelete={() => onDeleteTask(task.id)}
                />
            ))}
            <Button variant="primary" size="sm" onClick={onAddTask}>
                + Ajouter une tâche
            </Button>
        </div>
    );
};

export default ListColumn;