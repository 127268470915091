import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import { doc, getDoc, deleteDoc, addDoc, collection, onSnapshot, updateDoc } from 'firebase/firestore';
import { Container, Card, Button, Form, ListGroup, Modal } from 'react-bootstrap';
import { useUser } from '../context/UserContext';
import { FaEdit, FaTrashAlt, FaShareAlt, FaFacebookF, FaTwitter, FaLinkedinIn, FaEnvelope, FaWhatsapp, FaTelegramPlane, FaRedditAlien, FaPinterestP } from 'react-icons/fa';
import { BsArrowLeft } from 'react-icons/bs';
import './ArticleDetail.css';
import {
    EmailShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    RedditShareButton,
    PinterestShareButton,
} from "react-share";
import { Helmet } from 'react-helmet';

function ArticleDetail() {
    const { articleId } = useParams();
    const [article, setArticle] = useState(null);
    const [comment, setComment] = useState('');
    const navigate = useNavigate();
    const { currentUser, userRole } = useUser();
    const [comments, setComments] = useState([]);
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [editContent, setEditContent] = useState("");
    const [showEditModal, setShowEditModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const shareUrl = window.location.href;
    const createTextVersion = (htmlString) => {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        return doc.body.textContent || "";
    };
    const articleTitle = article ? createTextVersion(article.title) : '';
    const hashtags = ["DevIA", "TechFuture", "IntelligenceArtificielle", "InnovationTech", "Développeurs"];
    const shareMessage = `Un nouvel article est disponible : "${articleTitle}" Lisez-le maintenant !`;
  
   function truncateText(text, length) {
       if (text.length <= length) return text;
       let trimmedText = text.substr(0, length);
   
       trimmedText = trimmedText.substr(0, Math.min(trimmedText.length, trimmedText.lastIndexOf(" ")));
   
       return trimmedText + '...';
   }
    useEffect(() => {
        const fetchArticle = async () => {
            const docRef = doc(db, "Articles", articleId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setArticle({ id: docSnap.id, ...docSnap.data() });
            } else {
                console.log("No such article!");
            }
        };

        fetchArticle();
    }, [articleId]);

    const toggleShareModal = () => setShowShareModal(!showShareModal);

    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(db, "Articles", articleId, "Comments"),
            (snapshot) => {
                const commentsData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setComments(commentsData);
            }
        );
        return () => unsubscribe(); // Se désabonner de l'écouteur lors du démontage du composant
    }, [articleId]);
    const handleEditArticle = () => {
        if (currentUser && userRole === 'admin') {
            navigate(`/edit-article/${articleId}`);
        }
    };

    const handleDeleteArticle = async () => {
        if (currentUser && userRole === 'admin') {
            await deleteDoc(doc(db, "Articles", articleId));
            navigate("/");
        }
    };
    const handlePostComment = async () => {
        if (comment.trim() !== '' && currentUser) {
            await addDoc(collection(db, "Articles", articleId, "Comments"), {
                userId: currentUser.uid,
                userName: currentUser.displayName || "Anonyme",
                content: comment,
                createdAt: new Date()
            });
            setComment('');
            // Ici, vous pouvez ajouter une logique pour actualiser la liste des commentaires.
        }
    };

    // Fonction pour fermer la modal d'édition
    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setEditingCommentId(null);
    };
    const handleSaveEditedComment = async () => {
        if (editContent.trim() !== '' && editingCommentId) {
            const commentRef = doc(db, "Articles", articleId, "Comments", editingCommentId);
            try {
                await updateDoc(commentRef, {
                    content: editContent,
                    updatedAt: new Date(),
                });
                console.log('Commentaire mis à jour avec succès');
                setEditingCommentId(null); // Réinitialiser l'ID d'édition
                setEditContent(""); // Réinitialiser le contenu d'édition
                setShowEditModal(false); // Fermer la modal
                // Vous pouvez ajouter une logique pour rafraîchir les commentaires ici
            } catch (error) {
                console.error('Erreur lors de la mise à jour du commentaire: ', error);
            }
        }
    };


    const handleEditComment = (commentId) => {
        // Trouver le commentaire par son ID
        const commentToEdit = comments.find(comment => comment.id === commentId);

        // S'il existe, mettre à jour l'état avec ses données pour l'édition
        if (commentToEdit) {
            setEditingCommentId(commentToEdit.id);
            setEditContent(commentToEdit.content);
            setShowEditModal(true);
        }
    };


    const handleDeleteComment = async (commentId) => {
        if (currentUser && userRole === 'admin') {
            // Supprime le commentaire de Firestore
            await deleteDoc(doc(db, "Articles", articleId, "Comments", commentId));
            // Pas besoin de naviguer ailleurs, on reste sur la page
            console.log("Commentaire supprimé", commentId);
        }
    };

    return (
        <Container>
             <Helmet>
                <title>{article ? `${article.title} - IA Prompt Optimizer` : 'Chargement de l\'article...'}</title>
                <meta name="description" content={article ? truncateText(createTextVersion(article.content), 155) : 'Chargement...'} />
            </Helmet>
            <Button className="return-home-btn mt-4" onClick={() => navigate("/")}>
                <BsArrowLeft /> Retour à l'accueil
            </Button>
            {article ? (
                <Card>
                    <Card.Img variant="top" src={article.imageUrl} />
                    <Card.Body className='article-format'>
                        <div className="article-title" dangerouslySetInnerHTML={{ __html: article.title }} />
                        <div dangerouslySetInnerHTML={{ __html: article.content }} />
                        {currentUser && userRole === 'admin' && (
                            <div className="admin-actions">
                                <Button variant="outline-primary" onClick={handleEditArticle}>
                                    <FaEdit /> Éditer
                                </Button>
                                <Button variant="outline-danger" onClick={handleDeleteArticle}>
                                    <FaTrashAlt /> Supprimer
                                </Button>
                            </div>
                        )}
                        <Button variant="outline-info" onClick={toggleShareModal} className="ms-auto">
                            <FaShareAlt /> Partager
                        </Button>
                    </Card.Body>
                </Card>
            ) : (
                <p>Chargement de l'article...</p>
            )}
            <ListGroup variant="flush" className="comments-list">
                {comments.map((comment) => (
                    <ListGroup.Item key={comment.id} className="d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">{comment.userName}</div>
                            {comment.content}
                            <div className="text-muted">{new Date(comment.createdAt.seconds * 1000).toLocaleString()}</div>
                        </div>
                        {currentUser && userRole === 'admin' && (
                            <div className="comment-actions">
                                <FaEdit onClick={() => handleEditComment(comment.id)} className="comment-action-icon" />
                                <FaTrashAlt onClick={() => handleDeleteComment(comment.id)} className="comment-action-icon" />
                            </div>
                        )}
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <div>
                <h3>Commentaires</h3>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Ajouter un commentaire</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            maxLength={250}
                        />
                        <Form.Text className="text-muted">
                            {250 - comment.length} caractères restants
                        </Form.Text>
                    </Form.Group>
                    <Button onClick={handlePostComment} disabled={!currentUser}>Publier</Button>
                </Form>
            </div>

            <Modal show={showEditModal} onHide={handleCloseEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Éditer le commentaire</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Contenu du commentaire</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={editContent}
                            onChange={(e) => setEditContent(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleSaveEditedComment}>
                        Sauvegarder
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showShareModal} onHide={toggleShareModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Partager cet article</Modal.Title>
                </Modal.Header>
                <Modal.Body className="share-modal-body">
                    {/* Ici, insérez les boutons de partage avec des icônes */}
                    <div className="social-media-buttons">
                        <FacebookShareButton
                            url={shareUrl}
                            quote={shareMessage}
                            className="social-button facebook-button">
                            <FaFacebookF className="social-icon" /> Facebook
                        </FacebookShareButton>

                        <TwitterShareButton
                            url={shareUrl}
                            title={shareMessage}
                            hashtags={hashtags} // hashtags est un tableau de chaînes sans le symbole dièse
                            className="social-button twitter-button">
                            <FaTwitter className="social-icon" /> Twitter
                        </TwitterShareButton>

                        <LinkedinShareButton
                            url={shareUrl}
                            title={articleTitle}
                            className="social-button linkedin-button">
                            <FaLinkedinIn className="social-icon" /> LinkedIn
                        </LinkedinShareButton>


                        <EmailShareButton
                            url={shareUrl}
                            subject={`Un nouvel article est disponible : "${articleTitle}"`}
                            body={`${shareMessage} \nLisez-le maintenant : ${shareUrl} \n#DevIA #TechFuture #IntelligenceArtificielle #InnovationTech #Développeurs`}
                            className="social-button email-button">
                            <FaEnvelope className="social-icon" /> Email
                        </EmailShareButton>


                        <WhatsappShareButton
                            url={shareUrl}
                            title={`${shareMessage} \n${shareUrl} \n#DevIA #TechFuture #IntelligenceArtificielle #InnovationTech #Développeurs`}
                            separator=":: "
                            className="social-button whatsapp-button">
                            <FaWhatsapp className="social-icon" /> WhatsApp
                        </WhatsappShareButton>

                        <TelegramShareButton
                            url={shareUrl}
                            title={`${shareMessage} \n${shareUrl} \n#DevIA #TechFuture #IntelligenceArtificielle #InnovationTech #Développeurs`}
                            className="social-button telegram-button">
                            <FaTelegramPlane className="social-icon" /> Telegram
                        </TelegramShareButton>

                        <RedditShareButton
                            url={shareUrl}
                            title={shareMessage}
                            className="social-button reddit-button">
                            <FaRedditAlien className="social-icon" /> Reddit
                        </RedditShareButton>


                        <PinterestShareButton
                            url={shareUrl}
                            media={`${article ? article.imageUrl : ''}`}
                            description={`${shareMessage} #DevIA #TechFuture #IntelligenceArtificielle #InnovationTech #Développeurs`}
                            className="social-button pinterest-button">
                            <FaPinterestP className="social-icon" /> Pinterest
                        </PinterestShareButton>

                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default ArticleDetail;
