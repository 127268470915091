import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Container, Form, Button } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditArticle() {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchArticle = async () => {
      const docRef = doc(db, "Articles", articleId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const articleData = docSnap.data();
        setTitle(articleData.title);
        setContent(articleData.content);
      } else {
        console.log("No such article!");
      }
    };

    fetchArticle();
  }, [articleId]);

  const handleUpdateArticle = async () => {
    const articleRef = doc(db, "Articles", articleId);
    await updateDoc(articleRef, {
      title: title,
      content: content,
    });
    navigate(`/article/${articleId}`);
  };

  return (
    <Container>
      <h1>Éditer l'article</h1>
      <Form>
        <Form.Group className="mb-3" controlId="articleTitle">
          <Form.Label>Titre de l'article</Form.Label>
          <ReactQuill theme="snow" value={title} onChange={value => setTitle(value)} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="articleContent">
          <Form.Label>Contenu de l'article</Form.Label>
          <ReactQuill theme="snow" value={content} onChange={setContent} />
        </Form.Group>
        <Button variant="primary" onClick={handleUpdateArticle}>Mettre à jour l'article</Button>
      </Form>
    </Container>
  );
}

export default EditArticle;
