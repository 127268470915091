// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import getAuth for authentication
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage"; // Import getStorage for Firebase Storage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB2ainwTRGEVhwN67TQeyuLQJhhz7o0wU4",
  authDomain: "chat-gpt-prompt.firebaseapp.com",
  projectId: "chat-gpt-prompt",
  storageBucket: "chat-gpt-prompt.appspot.com",
  messagingSenderId: "304666478159",
  appId: "1:304666478159:web:09c3ee021ed9e833a60c3a",
  measurementId: "G-LP4LZT26JD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

let analytics;
if (typeof window !== "undefined") {
  analytics = getAnalytics(app);
}
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { app, analytics, auth, db, storage }; // Export storage
