import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase-config';
import { doc, getDoc, collection, getDocs, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import ListColumn from './ListColumn';
import InviteMembers from './InviteMembers';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './ProjectBoard.css';
import AddTaskModal from './AddTaskModal'; // Modal pour ajouter des tâches
import EditTaskModal from './EditTaskModal'; // Modal pour éditer des tâches
import NewListModal from './NewListModal'; // Assurez-vous que le chemin d'importation est correct
import { useUser } from '../context/UserContext';

const ProjectBoard = () => {
    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [lists, setLists] = useState([]);
    const [showAddTaskModal, setShowAddTaskModal] = useState(false);
    const [currentListId, setCurrentListId] = useState(null); // Pour savoir dans quelle liste ajouter une tâche
    const [showEditTaskModal, setShowEditTaskModal] = useState(false);
    const [currentTask, setCurrentTask] = useState(null); // Pour l'édition de tâche
    const [showNewListModal, setShowNewListModal] = useState(false); // Ajout de l'état pour le modal de création de liste
    const { currentUser } = useUser(); // Utilisation du contexte UserContext pour obtenir currentUser

    const handleCreateList = async (listData) => {
        try {
            const docRef = await addDoc(collection(db, "Projects", projectId, "Lists"), {
                title: listData.title,
                description: listData.description || '',
                tasks: [] // Initialiser avec un tableau vide
            });
            // Vous pourriez vouloir ajouter la nouvelle liste à l'état lists
            // pour mettre à jour l'interface utilisateur
            setLists(prevLists => [...prevLists, { id: docRef.id, ...listData, tasks: [] }]);
        } catch (error) {
            console.error("Erreur lors de la création de la liste:", error);
        }
    };

    useEffect(() => {
        const fetchProject = async () => {
            const docRef = doc(db, "Projects", projectId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setProject(docSnap.data());
            } else {
                console.log("No such project!");
            }
        };

        const fetchListsAndTasks = async () => {
            const listsRef = collection(db, "Projects", projectId, "Lists");
            const querySnapshot = await getDocs(listsRef);
            const listsDataPromises = querySnapshot.docs.map(async (docSnapshot) => {
                const listId = docSnapshot.id;
                const listData = { id: listId, ...docSnapshot.data() };
                const tasksSnapshot = await getDocs(collection(db, "Projects", projectId, "Lists", listId, "Tasks"));
                const tasksData = tasksSnapshot.docs.map(taskDoc => ({ id: taskDoc.id, ...taskDoc.data() }));
                return { ...listData, tasks: tasksData };
            });

            // Résoudre toutes les promesses pour obtenir les listes avec les tâches
            const listsData = await Promise.all(listsDataPromises);
            setLists(listsData);
        };

        fetchProject();
        fetchListsAndTasks();
    }, [projectId]);


    const handleAddTask = async (listId, taskData) => {
        console.log("Adding task to list ID:", listId); // Diagnostic
        if (!listId) {
            console.error("List ID is null or undefined.");
            return;
        }

        try {
            const newTaskRef = await addDoc(collection(db, "Projects", projectId, "Lists", listId, "Tasks"), taskData);
            const newTask = { id: newTaskRef.id, ...taskData };

            // Mettre à jour l'état avec la nouvelle tâche
            setLists(lists.map(list => {
                if (list.id === listId) {
                    // Trouvez la liste et ajoutez la nouvelle tâche
                    return {
                        ...list,
                        tasks: list.tasks ? [...list.tasks, newTask] : [newTask] // Si tasks n'est pas défini, créez un nouveau tableau
                    };
                } else {
                    // Pour les autres listes, retournez-les telles quelles
                    return list;
                }
            }));
        } catch (error) {
            console.error("Error adding task:", error);
        }
    };


    // Fonction pour éditer une tâche
    const handleEditTask = async (taskId, taskData) => {
        // Mettre à jour la tâche dans Firestore
        await updateDoc(doc(db, "Projects", projectId, "Lists", currentListId, "Tasks", taskId), taskData);
        // Mettre à jour l'état après la mise à jour réussie
        setLists(prevLists =>
            prevLists.map(list => {
                if (list.id === currentListId) {
                    return {
                        ...list,
                        tasks: list.tasks.map(task => {
                            if (task.id === taskId) {
                                return {
                                    ...task,
                                    ...taskData
                                };
                            } else {
                                return task;
                            }
                        })
                    };
                } else {
                    return list;
                }
            })
        );
    };

    // Fonction pour supprimer une tâche
    // Fonction pour supprimer une tâche
    const handleDeleteTask = async (taskId, listId) => {
        if (!listId) {
            console.error("List ID is null or undefined.");
            return;
        }
        try {
            await deleteDoc(doc(db, "Projects", projectId, "Lists", listId, "Tasks", taskId));
            // Mettre à jour l'état après la suppression réussie
            setLists(prevLists =>
                prevLists.map(list => {
                    if (list.id === listId) {
                        return {
                            ...list,
                            tasks: list.tasks.filter(task => task.id !== taskId)
                        };
                    } else {
                        return list;
                    }
                })
            );
        } catch (error) {
            console.error("Error deleting task:", error);
        }
    };


    const openAddTaskModal = (listId) => {
        setCurrentListId(listId);
        setShowAddTaskModal(true);
    };

    const closeAddTaskModal = () => {
        setShowAddTaskModal(false);
    };

    const openEditTaskModal = (task, listId) => {
        setCurrentTask(task);
        setCurrentListId(listId);
        setShowEditTaskModal(true);
    };

    const closeEditTaskModal = () => {
        setShowEditTaskModal(false);
    };
    const handleInvite = async (recipientId) => {
        try {
            // Créer une nouvelle invitation dans la collection "Invitations"
            const invitationsRef = collection(db, "Invitations");
            await addDoc(invitationsRef, {
                senderId: currentUser.uid, // L'identifiant de l'utilisateur qui envoie l'invitation
                recipientId: recipientId, // L'identifiant de l'utilisateur à qui l'invitation est envoyée
                projectId: projectId // L'identifiant du projet auquel l'utilisateur est invité
            });

            // Afficher un message de succès à l'utilisateur
            alert("Invitation sent successfully");
        } catch (error) {
            console.error("Error sending invitation:", error);
            // Gérer les erreurs éventuelles lors de l'envoi de l'invitation
            // Par exemple, afficher un message d'erreur à l'utilisateur
            alert(`Error sending invitation: ${error.message}`);
        }
    };
    
    if (!project) return <div>Loading project...</div>;

    return (
        <Container fluid className="board-page-container">
            <Row className="top-bar">
                <Col>
                    <h2>{project.title}</h2>
                    <p>{project.description}</p>
                </Col>
                <Col className="text-right">
                <InviteMembers onInvite={handleInvite} />
                </Col>
            </Row>
            <Button variant="primary" onClick={() => setShowNewListModal(true)} className="add-list-button">
                + Ajouter une liste
            </Button>
            <div className="list-container">
                {lists.map(list => (
                    <ListColumn
                        key={list.id}
                        title={list.title}
                        tasks={list.tasks || []}
                        onAddTask={() => openAddTaskModal(list.id)}
                        onEditTask={(task) => openEditTaskModal(task, list.id)}
                        onDeleteTask={(taskId) => handleDeleteTask(taskId, list.id)} // Passer listId également
                    />

                ))}
            </div>
            <AddTaskModal
                show={showAddTaskModal}
                handleClose={closeAddTaskModal}
                handleSave={(taskData) => handleAddTask(currentListId, taskData)} // `currentListId` est toujours défini lors de l'ouverture du modal
            />
            <EditTaskModal
                show={showEditTaskModal}
                handleClose={closeEditTaskModal}
                handleEdit={(taskData) => handleEditTask(currentTask.id, taskData)}
                task={currentTask}
            />
            <NewListModal
                show={showNewListModal}
                handleClose={() => setShowNewListModal(false)}
                handleCreateList={handleCreateList}
            />
        </Container>
    );
};

export default ProjectBoard;
