// apiFunction.js
import { db } from '../firebase-config';
import { doc, setDoc, onSnapshot } from 'firebase/firestore';

export const apiFunction = async (promptId, message) => {
  const docRef = doc(db, 'generate', promptId);

  await setDoc(docRef, {
      prompt: message,
      startTime: new Date(),
      state: 'PENDING',
  }, { merge: true });

  return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
          const data = docSnapshot.data();
          if (data && data.status && data.status.state === 'COMPLETED') {
              resolve(data.response);
              unsubscribe();
          } else if (data && data.state === 'COMPLETED') {
              resolve(data.response);
              unsubscribe();
          }
      }, (error) => {
          console.error('Error in onSnapshot:', error);
          reject(error);
      });
  });
};

