import React, { useState } from 'react';
import { Button, Form, Modal, InputGroup, FormControl } from 'react-bootstrap';

const InviteMembers = ({ onInvite }) => {
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleClose = () => {
        setShow(false);
        setEmail('');
        setError('');
    };

    const handleShow = () => setShow(true);

    const handleInvite = () => { // Changement du nom de la fonction ici
        // Vérifier si l'e-mail est valide
        if (!validateEmail(email)) {
            setError('Veuillez entrer une adresse e-mail valide.');
            return;
        }

        // Appeler la fonction onInvite pour ajouter le membre avec l'e-mail donné
        onInvite(email);

        // Réinitialiser les champs et fermer le modal après avoir envoyé l'invitation avec succès
        setEmail('');
        setShow(false);
    };

    // Fonction pour valider l'e-mail
    const validateEmail = (email) => {
        // Expression régulière pour valider l'e-mail
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Inviter des membres
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Inviter un nouveau membre</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Email du membre"
                                aria-label="Email du membre"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                isInvalid={!!error}
                            />
                            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                        </InputGroup>
                        <Button variant="primary" onClick={handleInvite}> {/* Utilisation de handleInvite ici */}
                            Inviter
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};


export default InviteMembers;
