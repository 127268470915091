export function reformatPrompt(prompt) {
    prompt = prompt.trim();

    // Replace "moir" with "moi" and other basic corrections
    prompt = prompt.replace(/ moir /g, " moi ").replace(/ , /g, ", ").replace(/\. ,/g, ".");

    // Ensure the prompt ends properly
    if (!prompt.endsWith(".") && !prompt.endsWith("?")) {
        prompt += ".";
    }

    const intro = "Imagine que tu es un développeur depuis plus de 20 ans, tes connaissances couvrent absolument toutes les technologies actuelles de développement ainsi que de l'environnement de dev. Tu vas m'aider à";

    // Integration of the introduction with a check to adjust the formulation
    if (!prompt.toLowerCase().startsWith("imagine que tu es un développeur")) {
        let verbIndex = prompt.search(/\b(faire|créer|développer|construire|élaborer)\b/);
        if (verbIndex !== -1) {
            // Construct a new sentence with an introduction and action
            let beforeVerb = prompt.substring(0, verbIndex).toLowerCase().trim();
            let action = prompt.slice(verbIndex);
            prompt = `${intro} ${beforeVerb} ${action.charAt(0).toLowerCase()}${action.slice(1)}`;
        } else {
            // If no specific action verb is found, use "réaliser" as a fallback verb
            prompt = `${intro} réaliser ${prompt.toLowerCase()}`;
        }
    }

    // Correct punctuation and ensure proper capitalization
    prompt = prompt
        .replace(/\. /g, ".")  // Remove spaces after periods to later capitalize the next letter correctly
        .replace(/([\.\?!])\s*([a-z])/g, (match, p1, p2) => `${p1} ${p2.toUpperCase()}`)  // Capitalize letters following punctuations
        .replace(/([\wÀ-ÖØ-öø-ÿ])\s*[,;:]\s*/g, "$1, ")  // Ensure correct spacing after commas and colons for French
        .replace(/([\wÀ-ÖØ-öø-ÿ])\s*\.\s*/g, "$1. ")  // Ensure correct spacing after periods
        .replace(/\s{2,}/g, " ");  // Replace multiple spaces with a single space

    return prompt;
}
