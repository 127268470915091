// components/ProtectedRoute.js
import React from 'react';
import { useUser } from '../context/UserContext';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children, requiredRole }) {
  const { currentUser, userRole } = useUser();

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/" />;
  }

  return children;
}

export default ProtectedRoute;
