import React, { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { FaTrophy, FaMedal, FaEye, FaEyeSlash } from 'react-icons/fa'; // Importer les icônes Font Awesome
import './Leaderboard.css';

const Leaderboard = () => {
  const [scores, setScores] = useState([]);
  const [showScores, setShowScores] = useState(true); // Ajouter un état pour gérer l'affichage des scores

  useEffect(() => {
    const fetchScores = async () => {
      const scoresCol = collection(db, "Scores");
      const q = query(scoresCol, orderBy("score", "desc"), limit(10));
      const querySnapshot = await getDocs(q);
      setScores(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchScores();
  }, []);

  const getIcon = (index) => {
    switch (index) {
      case 0:
        return <FaTrophy className="gold" />;
      case 1:
        return <FaMedal className="silver" />;
      case 2:
        return <FaMedal className="bronze" />;
      default:
        return null; // Pas d'icône pour les autres positions
    }
  };

  const toggleScores = () => {
    setShowScores(prevState => !prevState); // Inverser l'état d'affichage des scores
  };

  return (
    <div className="leaderboard">
      <h2>Leaderboard</h2>
      <button className="toggle-button" onClick={toggleScores}>
        {showScores ? <FaEyeSlash /> : <FaEye />} {/* Utiliser une icône différente en fonction de l'état */}
        {showScores ? 'Masquer' : 'Afficher'} les scores
      </button> {/* Ajouter un bouton pour afficher/masquer les scores */}
      {showScores && scores.map((score, index) => (
        <div key={index} className="leaderboard-entry">
          {getIcon(index)} {/* Afficher l'icône basée sur la position */}
          <span>{score.pseudo || 'Anonymous'}: {score.score}</span>
        </div>
      ))}
    </div>
  );
};

export default Leaderboard;
