import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AddTaskModal = ({ show, handleClose, handleSave }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const saveTask = () => {
        handleSave({ title, description });
        setTitle('');
        setDescription('');
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Ajouter une tâche</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Titre</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Entrez un titre pour la tâche"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Description de la tâche"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Fermer</Button>
                <Button variant="primary" onClick={saveTask}>Sauvegarder</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddTaskModal;
