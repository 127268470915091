import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase-config';
import { Container, Row, Col, Card, Button, Pagination } from 'react-bootstrap';
import './Home.css';
import SignUp from '../components/SignUp';
import { Fade, Slide } from 'react-awesome-reveal';
import promptsImage from '../assets/prompts.webp';
import discussionImage from '../assets/discussion.webp';
import articleImage from '../assets/article.webp';
import ameliorationImage from '../assets/amelioration.webp';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

function Home() {
    const [currentUser, setCurrentUser] = useState(null);
    const navigate = useNavigate();
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [articles, setArticles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [articlesPerPage] = useState(3);
    const totalPages = Math.ceil(articles.length / articlesPerPage);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchArticles = async () => {
            const articlesCollection = collection(db, 'Articles');
            const querySnapshot = await getDocs(query(articlesCollection, where('title', '!=', '')));
            let fetchedArticles = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                createdAt: doc.data().createdAt.toDate() // Convertit Timestamp en objet Date si nécessaire
            })).sort((a, b) => b.createdAt - a.createdAt);
        
            setArticles(fetchedArticles);
            setCurrentPage(1); // S'assure que vous commencez à la première page après le chargement des articles
        };
        
        fetchArticles();
    }, []); 
    
    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleManagePromptsClick = () => {
        if (!currentUser) {
            // Si l'utilisateur n'est pas connecté, afficher la modal d'inscription
            setShowSignUpModal(true);
        } else {
            // Si l'utilisateur est connecté, redirigez-le vers ManagePrompts
            navigate("/manage-prompts"); // Utiliser navigate pour la redirection
        }
    };

    const truncateText = (text, length) => {
        return text.length > length ? text.substring(0, length) + '...' : text;
    };

    const goToArticle = (id) => {
        navigate(`/article/${id}`);
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
    };
    
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    const items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => paginate(number)}
            >
                {number}
            </Pagination.Item>
        );
    }

    return (
        <Container className="home-page">
              <Helmet>
                <title>IA Prompt Optimizer</title>
                <meta name="description" content="Optimisée pour les développeurs, cette application vous permet de créer, améliorer et gérer vos prompts de manière efficace et intuitive." />
                <script type="application/ld+json">
                  {`
                    {
                      "@context": "https://schema.org",
                      "@type": "Organization",
                      "url": "https://ia-prompt-optimizer.com",
                      "logo": "https://ia-prompt-optimizer.com/logo512.png"
                    }
                  `}
                </script>
            </Helmet>
            <Fade duration={1000}>
                <Card className="text-center mb-5 card-background-image">
                    <Card.Header as="h2">Bienvenue sur notre Application de Gestion de Prompts</Card.Header>
                    <Card.Body>
                        <Card.Title>Découvrez et gérez vos prompts</Card.Title>
                        <Card.Text>
                            Optimisée pour les développeurs, cette application vous permet de créer, améliorer et gérer vos prompts de manière efficace et intuitive. Utilisez nos outils d'enrichissement et de reformulation pour optimiser vos prompts.
                        </Card.Text>
                        <Button onClick={handleManagePromptsClick} className="btn btn-primary">
                            <i className="fas fa-edit"></i> Gérer mes Prompts
                        </Button>
                    </Card.Body>
                </Card>
            </Fade>
            <Slide direction="up" duration={1000} triggerOnce>
                <Row>
                    <Col md={6} className="mb-4">
                        <Card className="feature-card">
                            <Card.Img variant="top" src={ameliorationImage} className="feature-card-img-top" />
                            <Card.Body className="feature-card-body">
                                <Card.Title className="feature-card-title">Création de Prompts</Card.Title>
                                <Card.Text className="feature-card-text">
                                    Ajoutez facilement de nouveaux prompts à votre collection grâce à une interface utilisateur intuitive.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card className="feature-card">
                            <Card.Img variant="top" src={promptsImage} className="feature-card-img-top" />
                            <Card.Body className="feature-card-body">
                                <Card.Title className="feature-card-title">Amélioration avec IA</Card.Title>
                                <Card.Text className="feature-card-text">
                                    Utilisez notre algorithme d'IA pour enrichir et améliorer vos prompts, rendant chaque session de génération de texte plus efficace.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card className="feature-card">
                            <Card.Img variant="top" src={discussionImage} className="feature-card-img-top" />
                            <Card.Body className="feature-card-body">
                                <Card.Title className="feature-card-title">Chat avec l'IA</Card.Title>
                                <Card.Text className="feature-card-text">
                                    Réutilisez vos prompts optimisés pour entamer une discussion directe avec l'IA et bénéficiez d'un accompagnement personnalisé.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card className="feature-card">
                            <Card.Img variant="top" src={articleImage} className="feature-card-img-top" />
                            <Card.Body className="feature-card-body">
                                <Card.Title className="feature-card-title">Suivi des Articles</Card.Title>
                                <Card.Text className="feature-card-text">
                                    Restez informés avec nos articles traitant des dernières innovations dans le domaine de l'IA et de l'IT.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Slide>

            <Slide direction="up" duration={1000} triggerOnce>
                <Row className="mb-4">
                    <Col>
                        <h2 className="articles-heading">Les Articles Autour de l'IA</h2>
                    </Col>
                </Row>
                <Row>
                    {currentArticles.map((article, index) => (
                        <Col key={index} md={4} className="mb-3">
                            <Card className="article-card">
                                <Card.Img variant="top" src={article.imageUrl || 'path/to/default/image.jpg'} />
                                <Card.Body>
                                    <div className="article-title" dangerouslySetInnerHTML={{ __html: article.title }} />
                                    <div dangerouslySetInnerHTML={{ __html: truncateText(article.content, 250) }} />
                                    <Button variant="primary" onClick={() => goToArticle(article.id)}>Lire l'article</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Slide>

            <Row>
                <Col className="text-center">
                    <div className="pagination-wrapper my-3">
                        <Pagination size="sm" className="justify-content-center">
                            <Pagination.Prev onClick={prevPage} disabled={currentPage === 1}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </Pagination.Prev>
                            {items}
                            <Pagination.Next onClick={nextPage} disabled={currentPage === totalPages}>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </Pagination.Next>
                        </Pagination>
                    </div>
                </Col>
            </Row>

            <SignUp show={showSignUpModal} closeSignUpModal={() => setShowSignUpModal(false)} />
        </Container>
    );
}

export default Home;
