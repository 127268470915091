import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Ajouté si vous avez besoin de redirection
import { auth } from '../firebase-config';
import {
    collection,
    query,
    where,
    getDocs,
    addDoc,
    deleteDoc,
    doc,
    updateDoc
} from 'firebase/firestore';
import { db } from '../firebase-config'; // Assurez-vous que l'importation db est correcte
import { Button, Form, ListGroup, Modal, Pagination } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ManagePrompts.css'; // Assurez-vous que le chemin est correct
import { enrichPrompt } from '../components/enrichPrompt';
import { improvePrompt } from '../components/improvePrompt';
import { reformatPrompt } from '../components/reformatPrompt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faShareAlt, faComments } from '@fortawesome/free-solid-svg-icons';
import {
    EmailShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    RedditShareButton,
    PinterestShareButton,
} from "react-share";
import { EmailIcon, TwitterIcon, LinkedinIcon, FacebookIcon, WhatsappIcon, TelegramIcon, RedditIcon, PinterestIcon } from "react-share"; // Import icons

function ManagePrompts() {
    const [prompts, setPrompts] = useState([]);
    const [currentPrompt, setCurrentPrompt] = useState("");
    const [editingId, setEditingId] = useState(null);
    const [show, setShow] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [promptsPerPage] = useState(5);
    const navigate = useNavigate(); // Ajouté pour la redirection
    const user = auth.currentUser;
    const [showShareModal, setShowShareModal] = useState(false);
    const [currentSharePrompt, setCurrentSharePrompt] = useState(null);

    // Fermer la modal
    const handleClose = () => {
        setShow(false);
        setEditingId(null);
        setCurrentPrompt("");
    };
    const handleShareClick = (prompt) => {
        setCurrentSharePrompt(prompt);
        setShowShareModal(true);
    };
    // Ouvrir la modal pour un nouveau prompt
    const handleShow = () => setShow(true);

    // Fetch des prompts existants
    const fetchPrompts = async () => {
        // Assurez-vous que l'utilisateur est connecté avant de tenter de récupérer ses prompts
        if (user) {
            const promptsCollectionRef = collection(db, "Prompts");
            const q = query(promptsCollectionRef, where("userId", "==", user.uid));
            const querySnapshot = await getDocs(q);
            setPrompts(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        }
    };

    useEffect(() => {
        if (!user) {
            navigate("/"); // Redirige vers la page de connexion si aucun utilisateur n'est connecté
            return;
        }

        const fetchPrompts = async () => {
            const q = query(collection(db, "Prompts"), where("userId", "==", user.uid));
            const querySnapshot = await getDocs(q);
            setPrompts(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        };

        fetchPrompts();
    }, [user, navigate]);

    const indexOfLastPrompt = currentPage * promptsPerPage;
    const indexOfFirstPrompt = indexOfLastPrompt - promptsPerPage;
    const currentPrompts = prompts.slice(indexOfFirstPrompt, indexOfLastPrompt);
    const totalPages = Math.ceil(prompts.length / promptsPerPage);

    const savePrompt = async () => {
        const user = auth.currentUser; // Obtenez l'utilisateur actuel

        if (editingId) {
            const promptDocRef = doc(db, "Prompts", editingId);
            await updateDoc(promptDocRef, {
                content: currentPrompt,
                updatedAt: new Date(), // Mettez à jour le timestamp ici
                userId: user.uid
            });
        } else {
            await addDoc(collection(db, "Prompts"), {
                content: currentPrompt,
                createdAt: new Date(),
                updatedAt: new Date(), // Ajoutez également le timestamp lors de la création
                userId: user.uid
            });
        }
        handleClose();
        fetchPrompts(); // Rafraîchir les prompts
    };


    // Définir le prompt à éditer
    const editPrompt = (id, content) => {
        setEditingId(id);
        setCurrentPrompt(content);
        handleShow();
        fetchPrompts(); // Rafraîchir les prompts

    };

    // Supprimer un prompt
    const deletePrompt = async (id) => {
        const promptDoc = doc(db, "Prompts", id);
        await deleteDoc(promptDoc);
        // Rafraichir les prompts

        fetchPrompts(); // Rafraîchir les prompts

    };

    const handleImprovePrompt = () => {
        let improvedPrompt = currentPrompt;
        const improvementSuggestions = improvePrompt(improvedPrompt);
        improvedPrompt = enrichPrompt(improvedPrompt); // Enrichir le prompt
        improvedPrompt = reformatPrompt(improvedPrompt); // Reformater le prompt
        setSuggestions(improvementSuggestions);
        setCurrentPrompt(improvedPrompt); // Appliquez la reformulation directement si vous le souhaitez
    };

    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => setCurrentPage(number)}>
                {number}
            </Pagination.Item>,
        );
    }

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToChatBox = (promptId, promptContent) => {
        navigate(`/chatbox/${promptId}`, { state: { promptId: promptId, promptContent: promptContent } });
    };

    return (
        <div className="p-3 manageprompts">
            <Button variant="primary" onClick={handleShow}>Ajouter un Prompt</Button>
            <ListGroup className="mt-3">
                {currentPrompts.map((prompt) => (
                    <ListGroup.Item key={prompt.id}>
                        <div className="prompt-item">
                            <div className="prompt-metadata">
                                <div className="prompt-created">
                                    Créé par : {prompt.createdBy || user.email}, le {new Date(prompt.createdAt?.toDate()).toLocaleDateString()}
                                </div>
                                {prompt.updatedAt && (
                                    <div className="prompt-updated">
                                        Mis à jour le {new Date(prompt.updatedAt?.toDate()).toLocaleDateString()}
                                    </div>
                                )}
                            </div>
                            <div className="prompt-content">
                                {prompt.content}
                            </div>
                            {user && prompt.userId === user.uid && (
                                <>
                                    <Button variant="info" size="sm" className="edit-btn" onClick={() => editPrompt(prompt.id, prompt.content)}>
                                        <i className="fas fa-edit"></i>
                                    </Button>
                                    <Button variant="danger" size="sm" className="delete-btn" onClick={() => deletePrompt(prompt.id)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </Button>
                                    <div className='mt-3 button-group'>
                                        <Button onClick={() => handleShareClick(prompt)} className="mr-2">
                                            <FontAwesomeIcon icon={faShareAlt} /> Share
                                        </Button>
                                        <Button onClick={() => goToChatBox(prompt.id, prompt.content)}>
                                            <FontAwesomeIcon icon={faComments} /> Chat
                                        </Button>
                                    </div>

                                </>
                            )}
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>

            <div className="pagination-wrapper my-3">
                <Pagination size="sm" className="justify-content-center">
                    <Pagination.Prev onClick={prevPage} disabled={currentPage === 1}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Pagination.Prev>
                    {items}
                    <Pagination.Next onClick={nextPage} disabled={currentPage === totalPages}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Pagination.Next>
                </Pagination>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingId ? "Éditer Prompt" : "Nouveau Prompt"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Contenu du Prompt</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={currentPrompt}
                                onChange={(e) => setCurrentPrompt(e.target.value)}
                            />
                        </Form.Group>
                        {suggestions.length > 0 && (
                            <div className="mt-3">
                                <h5>Suggestions d'amélioration :</h5>
                                <ul className="suggestions-list">
                                    {suggestions.map((suggestion, index) => (
                                        <li key={index}>{suggestion}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <div className="mt-3">
                            <Button variant="secondary" onClick={handleImprovePrompt} className="me-2">
                                Améliorer
                            </Button>
                            <Button variant="primary" onClick={savePrompt}>
                                Sauvegarder
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>

            </Modal>

            <Modal show={showShareModal} onHide={() => setShowShareModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Partager le Prompt</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body-instructions">
                        <p>
                            Partager vos idées peut accroître votre visibilité et ouvrir des discussions enrichissantes.
                            Utilisez les boutons ci-dessous pour partager ce prompt sur vos réseaux sociaux préférés et
                            inviter d'autres à contribuer à la conversation.
                        </p>
                    </div>
                    <div className="share-buttons-container">

                        <EmailShareButton url={window.location.href} subject="Check out this prompt!" body={currentSharePrompt?.content}>
                            <Button variant="outline-primary" className="mr-2">
                                <EmailIcon size={32} round className="share-icon" /> Email
                            </Button>
                        </EmailShareButton>
                        <TwitterShareButton url={window.location.href} title={currentSharePrompt?.content}>
                            <Button variant="outline-info" className="mr-2">
                                <TwitterIcon size={32} round className="share-icon" /> Twitter
                            </Button>
                        </TwitterShareButton>
                        <LinkedinShareButton url={window.location.href} summary={currentSharePrompt?.content}>
                            <Button variant="outline-secondary">
                                <LinkedinIcon size={32} round className="share-icon" /> LinkedIn
                            </Button>
                        </LinkedinShareButton>
                        <FacebookShareButton url={window.location.href} quote={currentSharePrompt?.content}>
                            <Button variant="outline-primary" className="mr-2"><FacebookIcon size={32} round className="share-icon" /> Facebook</Button>
                        </FacebookShareButton>
                        <WhatsappShareButton url={window.location.href} title={currentSharePrompt?.content}>
                            <Button variant="outline-success" className="mr-2"><WhatsappIcon size={32} round className="share-icon" /> WhatsApp</Button>
                        </WhatsappShareButton>
                        <TelegramShareButton url={window.location.href} title={currentSharePrompt?.content}>
                            <Button variant="outline-primary" className="mr-2"><TelegramIcon size={32} round className="share-icon" /> Telegram</Button>
                        </TelegramShareButton>
                        <RedditShareButton url={window.location.href} title={currentSharePrompt?.content}>
                            <Button variant="outline-warning" className="mr-2"><RedditIcon size={32} round className="share-icon" /> Reddit</Button>
                        </RedditShareButton>
                        <PinterestShareButton url={window.location.href} media="/* Image URL */" description={currentSharePrompt?.content}>
                            <Button variant="outline-danger"><PinterestIcon size={32} round className="share-icon" /> Pinterest</Button>
                        </PinterestShareButton>
                    </div>
                    {/* Add more share buttons as needed */}
                </Modal.Body>
            </Modal>

        </div>
    );
}

export default ManagePrompts;
