import React from 'react';
import { Card } from 'react-bootstrap';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import './TaskCard.css'; // Vérifiez que le chemin d'importation est correct

const TaskCard = ({ task, onEdit, onDelete }) => {
    return (
        <Card className="task-card mb-2">
            <Card.Body className="task-card-body">
                <Card.Title className="task-card-title">{task.title}</Card.Title>
                <Card.Text className="task-card-text">
                    {task.description}
                </Card.Text>
                <div className="task-card-actions">
                    <button className="task-card-button" onClick={() => onEdit(task)}>
                        <AiOutlineEdit />
                    </button>
                    <button className="task-card-button" onClick={() => onDelete(task.id)}>
                        <AiOutlineDelete />
                    </button>
                </div>
            </Card.Body>
        </Card>
    );
};

export default TaskCard;
