// MyNavbar.jsx
import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav, Button, Modal, Dropdown } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import { auth, db } from '../firebase-config';
import { signOut } from 'firebase/auth';
import './Navbar.css';
import SignUp from './SignUp';
import SignIn from './SignIn';
import { Link, useNavigate } from 'react-router-dom'; // Import de useNavigate
import logoImage from '../assets/logo.png';
import { doc, getDoc } from 'firebase/firestore';
import { Tools } from 'react-bootstrap-icons'; // Assure-toi que l'icône Tools est bien disponible dans react-bootstrap-icons
import { useUser } from '../context/UserContext';

const MyNavbar = () => {
    const [user, setUser] = useState(null);
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const navigate = useNavigate(); // Utilisation de useNavigate
    const [isAdmin, setIsAdmin] = useState(false);
    const { currentUser, profileData } = useUser(); // Utilisation des données de profil depuis le contexte

    // Écouter les changements de l'état de connexion
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                // L'utilisateur est connecté
                setUser(user);
                // Récupérer les données de l'utilisateur pour vérifier le rôle
                const userRef = doc(db, 'Users', user.uid);
                getDoc(userRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        setIsAdmin(docSnap.data().role === 'admin');
                    }
                });
            } else {
                // L'utilisateur est déconnecté
                setUser(null);
                setIsAdmin(false);
            }
        });
    }, []);

    const handleCloseSignIn = () => setShowSignInModal(false);
    const handleShowSignIn = () => setShowSignInModal(true);

    const handleSignOut = () => {
        signOut(auth)
            .then(() => {
                // Rediriger vers la page d'accueil après la déconnexion
                navigate("/");
            })
            .catch((error) => {
                console.error('Erreur de déconnexion', error);
            });
    };

    const handleSignUpClick = () => {
        setShowSignUpModal(true); // Ouvrir la modal d'inscription
    };

    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
                <Container>
                    <Navbar.Brand as={Link} to="/">
                        <img
                            src={logoImage}
                            width="50" // ajustez la largeur selon vos besoins
                            height="50" // ajustez la hauteur selon vos besoins
                            className="d-inline-block align-top"
                            alt="Logo Mon App"
                        />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/">Accueil</Nav.Link>
                            {user && <Nav.Link as={Link} to="/manage-prompts">Créer des Prompts</Nav.Link>}
                            {user && (
                                <Nav.Link as={Link} to="/projects" className="work-in-progress-link d-flex align-items-center">
                                    <div className="icon-and-text">
                                        <Tools size={24} className="work-in-progress-icon" />
                                        Créer vos projets
                                    </div>
                                </Nav.Link>
                            )}


                            {user && <Nav.Link as={Link} to="/game">Le Défi des Chiffres de l'IA</Nav.Link>}
                            {/* Affichez le lien uniquement si l'utilisateur est connecté */}
                            {isAdmin && <Nav.Link as={Link} to="/dashboard">Dashboard</Nav.Link>}
                        </Nav>
                        {currentUser ? (
                            <>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="me-3">
                                        <PersonCircle size={24} className="greeting-icon" /> {/* Icône ajoutée ici */}
                                        Bonjour, {profileData.pseudo || currentUser.email}
                                    </Dropdown.Toggle>


                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/profile">
                                            Voir le profil
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={handleSignOut}>
                                            Déconnexion
                                        </Dropdown.Item>
                                        {/* Vous pouvez ajouter d'autres éléments ici si nécessaire */}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button variant="outline-danger" onClick={handleSignOut}>
                                    Déconnexion
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button variant="outline-success" onClick={handleShowSignIn} className="me-2">
                                    <PersonCircle size={24} /> Connexion
                                </Button>
                                <Button variant="outline-info" onClick={handleSignUpClick}>
                                    <PersonCircle size={24} /> Inscription
                                </Button>
                            </>
                        )}
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* Modals */}
            <SignUp show={showSignUpModal} closeSignUpModal={() => setShowSignUpModal(false)} handleShowSignIn={handleShowSignIn} />
            <Modal show={showSignInModal} onHide={handleCloseSignIn}>
                <Modal.Header closeButton>
                    <Modal.Title>Connexion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SignIn closeSignInModal={handleCloseSignIn} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default MyNavbar;
