import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Google } from 'react-bootstrap-icons';
import { signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase-config';
import { db } from '../firebase-config'; // Assurez-vous que l'importation db est correcte
import { doc, setDoc } from 'firebase/firestore'; // Importer les fonctions nécessaires depuis Firestore
import 'bootstrap/dist/css/bootstrap.min.css';

function SignUp({ show, closeSignUpModal, handleShowSignIn }) {
    const registerWithEmailPassword = async (event) => {
        event.preventDefault();

        const email = event.target.elements.email.value;
        const password = event.target.elements.password.value;

        try {
            // Créer le compte utilisateur avec l'e-mail et le mot de passe
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);

            // Définir le rôle de l'utilisateur dans Firestore
            await setDoc(doc(db, "Users", userCredential.user.uid), {
                email: email,
                role: "user" // le rôle par défaut est 'user'
                // Ajoutez d'autres champs si nécessaire
            });

            console.log('Utilisateur créé avec succès:', userCredential.user);
            closeSignUpModal();
        } catch (error) {
            console.error('Erreur lors de la création du compte:', error.message);
        }
    };

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            // Vérifiez si l'utilisateur existe déjà dans Firestore avant de définir le rôle
            const userDocRef = doc(db, "Users", result.user.uid);
            const userDoc = await userDocRef.get();
            if (!userDoc.exists) {
                await setDoc(userDocRef, {
                    email: result.user.email,
                    role: "user" // le rôle par défaut est 'user'
                    // Ajoutez d'autres champs si nécessaire
                });
            }
            console.log(result.user);
            closeSignUpModal();
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <Modal show={show} onHide={closeSignUpModal}>
            <Modal.Header closeButton>
                <Modal.Title>Inscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={registerWithEmailPassword}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Adresse e-mail</Form.Label>
                        <Form.Control type="email" name="email" placeholder="Entrez votre e-mail" required />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Mot de passe</Form.Label>
                        <Form.Control type="password" name="password" placeholder="Mot de passe" required />
                    </Form.Group>

                    <Button variant="success" type="submit">
                        S'inscrire avec Email
                    </Button>
                </Form>
                <hr />
                <Button variant="danger" onClick={signInWithGoogle} className="w-100">
                    <Google className="me-2" /> S'inscrire avec Google
                </Button>
                <p className="mt-3 text-center">
                    Vous avez déjà un compte?{' '}
                    <Button variant="link" onClick={handleShowSignIn}>
                        Connectez-vous ici
                    </Button>
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default SignUp;
