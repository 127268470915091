// TermsOfUsePage.jsx

import React from 'react';
import { Container } from 'react-bootstrap';
import './TermsOfUsePage.css'; // Assurez-vous de créer ce fichier CSS pour ajouter vos styles

const TermsOfUsePage = () => {
    return (
        <Container className="terms-container">
            <h1>Conditions d'utilisation</h1>
            <p>Merci d'utiliser IA Prompt Optimizer. En accédant à notre site Web, vous acceptez de respecter les conditions d'utilisation suivantes :</p>
            <h2>1. Utilisation du site</h2>
            <p>Vous acceptez d'utiliser IA Prompt Optimizer uniquement à des fins légales et conformément à ces conditions d'utilisation.</p>
            <h2>2. Contenu</h2>
            <p>Tout le contenu affiché sur IA Prompt Optimizer est la propriété intellectuelle de l'équipe DivWeb. Vous ne pouvez pas copier, reproduire, distribuer ou créer des œuvres dérivées de ce contenu sans notre autorisation.</p>
            <h2>3. Confidentialité</h2>
            <p>Nous respectons votre vie privée. Consultez notre <a href="/privacy">Politique de confidentialité</a> pour en savoir plus sur la manière dont nous collectons, utilisons et protégeons vos données personnelles.</p>
            <h2>4. Modifications</h2>
            <p>Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Nous vous recommandons de consulter régulièrement cette page pour rester informé des mises à jour.</p>
            <p>Si vous avez des questions concernant ces conditions d'utilisation, veuillez nous contacter à l'adresse suivante : contact@iapromptoptimizer.com</p>
        </Container>
    );
};

export default TermsOfUsePage;
