import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { useUser } from '../context/UserContext';
import { db } from '../firebase-config';
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc, arrayUnion } from 'firebase/firestore'; // Importer 'doc' depuis firebase/firestore
import { useNavigate } from 'react-router-dom';
import './ProjectsPage.css'; // Import de la feuille de style CSS

const ProjectsPage = () => {
    const [projects, setProjects] = useState([]);
    const [invitations, setInvitations] = useState([]);
    const { currentUser } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProjects = async () => {
            if (currentUser) {
                const projectsRef = collection(db, "Projects");
                const projectsQuery = query(projectsRef, where("members", "array-contains", currentUser.uid));
                const projectsSnapshot = await getDocs(projectsQuery);
                const projectsData = projectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProjects(projectsData);

                const invitationsRef = collection(db, "Invitations");
                const invitationsQuery = query(invitationsRef, where("recipientId", "==", currentUser.email));
                const invitationsSnapshot = await getDocs(invitationsQuery);
                const invitationsData = invitationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                console.log(invitationsData); // Ajoutez cette ligne pour déboguer
                setInvitations(invitationsData);
                
            }
        };

        fetchProjects();
    }, [currentUser]);

    const handleCreateProject = () => {
        navigate("/create-project");
    };

    const handleAcceptInvitation = async (invitation) => {
        console.log("Invitation ID:", invitation.id); // Devrait afficher l'ID de l'invitation
        console.log("Project ID:", invitation.projectId); // Devrait afficher l'ID du projet
        try {
            // Supprime l'invitation de Firestore
            await deleteDoc(doc(db, "Invitations", invitation.id));
    
            // Ensuite, ajoute l'utilisateur actuel aux membres du projet
            const projectRef = doc(db, "Projects", invitation.projectId);
            await updateDoc(projectRef, {
                members: arrayUnion(currentUser.uid)
            });
    
            // Enfin, navigue vers la page du projet
            navigate(`/project/${invitation.projectId}`);
        } catch (error) {
            console.error("Error accepting invitation:", error);
        }
        
    };
    
    
    

    const handleDeclineInvitation = async (invitationId) => {
        try {
            await deleteDoc(doc(db, "Invitations", invitationId));
            setInvitations(invitations.filter(invitation => invitation.id !== invitationId));
        } catch (error) {
            console.error("Error declining invitation:", error);
        }
    };

    return (
        <Container className="projects-page-container">
            <Row className="mt-3">
                <Col>
                    <Button onClick={handleCreateProject}>Créer un Projet</Button>
                </Col>
            </Row>
            <Row className="mt-3">
                {projects.map((project) => (
                    <Col key={project.id} md={4} className="mb-3">
                        <Card className='projectCard'>
                            <Card.Body className='projectBody'>
                                <Card.Title className='projectTitle text-dark'>{project.title}</Card.Title>
                                <Card.Text className='projectDesc text-dark'>{project.description}</Card.Text>
                                <Button variant="primary" onClick={() => navigate(`/project/${project.id}`)}>Ouvrir le Projet</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
                {/* Affichage des invitations */}
                {invitations.map((invitation) => (
                    <Col key={invitation.id} md={4} className="mb-3">
                        <Card className='invitationCard'>
                            <Card.Body className='invitationBody'>
                                <Card.Title className='invitationTitle'>Invitation à rejoindre {invitation.projectId}</Card.Title>
                                <Card.Text className='invitationDesc'>Vous avez reçu une invitation pour ce projet.</Card.Text>
                                <Button variant="primary" onClick={() => handleAcceptInvitation(invitation)}>Accepter l'invitation</Button>
                                <Button variant="danger" onClick={() => handleDeclineInvitation(invitation.id)}>Refuser l'invitation</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}

            </Row>
        </Container>
    );
};

export default ProjectsPage;

