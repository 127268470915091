export function improvePrompt(prompt) {
    let existingSuggestions = new Map();

    function addSuggestion(prompt, suggestion) {
        // Mise à jour avec les dernières versions ou LTS connues et ajout de logiciels de développement
        const techVersions = {
            "React": "18.0.0", // Version mise à jour pour l'exemple
            "Vue.js": "3.2.31",
            "Angular": "13.3.0",
            "Node.js": "16.14.0", // Exemple de version LTS
            "WordPress": "5.9",
            "VSCode": "1.64", // Exemple de version de logiciel de développement
            "IntelliJ IDEA": "2021.3", // Autre logiciel de développement
            "Docker": "20.10.12", // Exemple de technologie de conteneurisation
            "Kubernetes": "1.23", // Technologie d'orchestration de conteneurs
            "Python": "3.10.2", // Langage de programmation
            "Django": "3.2.10", // Framework pour Python
            "Flask": "2.0.2" // Autre framework pour Python
        };
    
        let techFound = false; // Pour vérifier si une technologie a été trouvée
    
        // Détecter les technologies mentionnées dans le prompt
        for (const tech in techVersions) {
            if (prompt.includes(tech)) {
                // Personnaliser la suggestion avec la version spécifique
                suggestion += ` Assurez-vous d'utiliser la version ${techVersions[tech]} de ${tech} pour bénéficier des dernières fonctionnalités et corrections de sécurité.`;
                techFound = true;
                break; // Supposons qu'on ne mentionne qu'une technologie à la fois pour simplifier
            }
        }
    
        if (!techFound) {
            // Si aucune technologie spécifique n'est mentionnée, donner une suggestion générale sur l'importance des mises à jour
            suggestion += " Assurez-vous de toujours utiliser les dernières versions ou les versions LTS des technologies pour garantir la sécurité et l'efficacité.";
        }
    
        // Enregistrer la suggestion
        if (!existingSuggestions.has(prompt) || !existingSuggestions.get(prompt).includes(suggestion)) {
            existingSuggestions.set(prompt, [...(existingSuggestions.get(prompt) || []), suggestion]);
        }
    }
    

    function getSuggestions(prompt) {
        return existingSuggestions.has(prompt) ? existingSuggestions.get(prompt) : [];
    }

    let suggestions = [];

    if (!existingSuggestions.has(prompt)) {
        const intro = "Imagine que tu es un développeur depuis plus de 20 ans, tes connaissances couvrent absolument toutes les technologies actuelles de développement ainsi que de l'environnement de dev. Tu vas m'aider pour";

        if (!isFrench(prompt)) {
            addSuggestion(prompt, "Il semble que votre prompt ne soit pas en français. Veuillez utiliser des phrases en français.");
        }

        if (!isSemanticallyCoherent(prompt)) {
            addSuggestion(prompt, "Votre prompt ne semble pas cohérent. Vérifiez que les phrases ont un sens logique.");
        }

        if (prompt.length < 20) {
            addSuggestion(prompt, "Votre prompt semble assez court. Envisagez d'ajouter plus de détails.");
        } else if (prompt.length > 200) {
            addSuggestion(prompt, "Votre prompt est très long. Essayez de le rendre plus concis ou de focaliser sur un point spécifique.");
        }

        if (!prompt.includes("?") && !prompt.endsWith(".")) {
            addSuggestion(prompt, "Votre prompt pourrait être plus clair sous forme de question ou de déclaration complète.");
        }

        if (!prompt.trim().toLowerCase().startsWith("imagine que tu es un développeur")) {
            addSuggestion(prompt, `En commençant votre prompt par '${intro}', vous pouvez encourager des réponses plus créatives et immersives.`);
        }

        if (!prompt.includes("ChatGPT")) {
            addSuggestion(prompt, "Mentionner 'ChatGPT' dans votre prompt peut clarifier à qui vous vous adressez.");
        }

        if (!prompt.includes("Comment") && !prompt.includes("Pourquoi") && !prompt.includes("Explique")) {
            addSuggestion(prompt, "Utiliser des mots comme 'Comment', 'Pourquoi', ou 'Explique' peut rendre votre prompt plus engageant.");
        }
    }

    suggestions = getSuggestions(prompt);

    return suggestions.length > 0 ? suggestions : ["Votre prompt semble bien formulé."];
}

function isFrench(prompt) {
    const frenchWords = ['le', 'la', 'les', 'un', 'une', 'pourquoi', 'comment', 'quand', 'où', 'qui', 'que', 'quel', 'quelle', 'et', 'mais', 'ou', 'donc', 'or', 'ni', 'car'];
    return frenchWords.some(word => prompt.toLowerCase().includes(word));
}

function isSemanticallyCoherent(prompt) {
    const incoherentPatterns = [' et et ', ' ou ou ', ' mais mais '];
    return !incoherentPatterns.some(pattern => prompt.includes(pattern));
}
