import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth, db } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { updatePassword } from 'firebase/auth';

export const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  // Ajout d'un nouvel état pour stocker les données de profil supplémentaires
  const [profileData, setProfileData] = useState({}); // État pour stocker les données de profil de Firestore.

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setCurrentUser(user);
      if (user) {
        const userDocRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          setUserRole(docSnap.data().role);
          // Stockez les données supplémentaires de profil si elles existent
          setProfileData(docSnap.data());
        } else {
          setUserRole(null);
          setProfileData({});
        }
      } else {
        setUserRole(null);
        setProfileData({});
      }
    });

    return () => unsubscribe;
  }, []);

  const updateUserProfile = async (userData) => {
    if (!currentUser) return;

    const userRef = doc(db, "Users", currentUser.uid);
    await updateDoc(userRef, userData);
    // Mise à jour des données de profil dans le contexte
    setProfileData(prev => ({ ...prev, ...userData }));
};


  const updateUserPassword = async (newPassword) => {
    if (currentUser) {
      await updatePassword(currentUser, newPassword);
    }
  };

  const value = {
    currentUser,
    userRole,
    profileData, // Incluez les données de profil dans la valeur fournie par le contexte
    setUserRole,
    updateUserProfile,
    updateUserPassword
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
