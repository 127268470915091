import React from 'react';
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './Footer.css'; // Assurez-vous de créer ce fichier CSS pour ajouter vos styles
import logo from '../assets/logo.png'; // Ajustez le chemin selon la structure de votre projet

const Footer = () => {
    return (
        <Navbar expand="lg" variant="dark" bg="dark" className="animate__animated animate__fadeInUp">
            <Container>
                <Row className="w-100">
                    <Col md={6} className="text-center text-md-left mb-3 mb-md-0">
                        <Navbar.Brand href="/" className="brand-logo">
                            <img src={logo} width="30" height="30" className="d-inline-block align-top" alt="Logo" />
                            {' '}
                            IA Prompt Optimizer
                        </Navbar.Brand>
                        <Nav className="mr-auto">
                            <Nav.Link href="/privacy">Politique de confidentialité</Nav.Link>
                            <Nav.Link href="/terms">Conditions d'utilisation</Nav.Link>
                        </Nav>
                    </Col>
                    <Col md={6} className="text-center text-md-right">
                        <p>
                            Projet réalisé par{' '}
                            <a href="http://divweb.fr/" target="_blank" rel="noopener noreferrer">
                                l'équipe DivWeb
                            </a>
                            , dirigé par{' '}
                            <a href="https://www.linkedin.com/in/pierre-romain-lopez/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} className="ml-2" />
                                Pierre-Romain LOPEZ
                            </a>
                        </p>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    );
};

export default Footer;
