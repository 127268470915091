import React, { useState } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { db } from '../firebase-config';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import './CreateProjectForm.css';

const CreateProjectForm = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { currentUser } = useUser();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title || !description) {
            setError('Please fill in all fields.');
            return;
        }

        try {
            // Ajoutez le nouveau projet à Firestore.
            const docRef = await addDoc(collection(db, 'Projects'), {
                title,
                description,
                owner: currentUser.uid,
                members: [currentUser.uid], // Owner is automatically a member.
                createdAt: new Date(),
            });

            navigate(`/project/${docRef.id}`);
        } catch (err) {
            setError('Error creating project: ' + err.message);
        }
    };

    return (
        <Container className='projectForm-page-container'>
            <h1>Create New Project</h1>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="projectTitle">
                    <Form.Label>Project Title</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter project title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="projectDescription">
                    <Form.Label>Project Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter project description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Create Project
                </Button>
            </Form>
        </Container>
    );
};

export default CreateProjectForm;
