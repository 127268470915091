// En haut de votre fichier Dashboard.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Button, Modal, Form } from 'react-bootstrap';
import { db, storage } from '../firebase-config';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './Dashboard.css';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Inclure les styles CSS
import { faEdit, faBan, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Dashboard() {
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [articleTitle, setArticleTitle] = useState("");
    const [articleContent, setArticleContent] = useState("");
    const [articleImage, setArticleImage] = useState(null);
    const functions = getFunctions(); // Initialisation de Firebase Functions
    const auth = getAuth();
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const getUsers = async () => {
            const querySnapshot = await getDocs(collection(db, 'Users'));
            setUsers(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        };

        getUsers();
    }, []);

    useEffect(() => {
        // Ici, 'auth' doit être inclus dans le tableau des dépendances
        // puisqu'il est utilisé dans le corps de `useEffect`.
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
                // Autres actions lorsque l'utilisateur est connecté
            } else {
                // L'utilisateur est déconnecté
                setCurrentUser(null);
            }
        });

        // Nettoyer l'abonnement à onAuthStateChanged lors du démontage du composant
        return () => unsubscribe();
    }, [auth]); // Ajouter 'auth' ici

    onAuthStateChanged(auth, (user) => {
        if (user) {
            user.getIdTokenResult()
                .then((idTokenResult) => {
                    console.log(idTokenResult.claims); // Voir tous les claims
                    if (idTokenResult.claims.admin) {
                        console.log("L'utilisateur est un admin");
                        // L'utilisateur est un admin, vous pouvez ici ajouter votre logique pour les admins
                    } else {
                        console.log("L'utilisateur n'est pas un admin");
                        // L'utilisateur n'est pas un admin
                    }
                })

                .catch((error) => {
                    console.error("Erreur lors de la récupération des claims :", error);
                });

        } else {
            // Utilisateur non connecté
            console.log("Aucun utilisateur connecté");
        }
    });

    auth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
            console.log(idTokenResult.claims); // Voir tous les claims
            if (idTokenResult.claims.admin) {
                // L'utilisateur est un admin
            }
        })
        .catch((error) => {
            console.error("Erreur lors de la récupération du token:", error);
        });

    const handleRoleChange = async (userId, newRole) => {
        // Appeler la fonction Cloud pour changer le rôle de l'utilisateur
        const changeUserRole = httpsCallable(functions, 'changeUserRole');
        try {
            await changeUserRole({ uid: userId, newRole });
            console.log(`Rôle de ${userId} changé en ${newRole}`);
        } catch (error) {
            console.error("Erreur lors du changement de rôle:", error);
        }
    };

    const handleBanUser = (userId) => {
        // Logique pour bannir un utilisateur
        console.log(`Bannir l'utilisateur ${userId}`);
    };

    const handleShowModal = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };
    const handleSaveArticle = async () => {
        if (!articleImage) {
            console.log("Aucune image sélectionnée");
            return;
        }
        if (!currentUser) {
            console.error("Aucun utilisateur connecté pour créer un article");
            return;
        }

        try {
            const imageRef = ref(storage, `articles/${articleImage.name}`);
            const snapshot = await uploadBytes(imageRef, articleImage);
            const imageUrl = await getDownloadURL(snapshot.ref);

            const docRef = await addDoc(collection(db, "Articles"), {
                authorId: currentUser.uid, // Utilise l'UID de l'utilisateur connecté
                title: articleTitle,
                content: articleContent,
                imageUrl,
                createdAt: new Date() // ou autre logique pour définir la date
            });

            console.log("Article publié avec succès, ID:", docRef.id);
            setShowModal(false);
            // Ici tu peux ajouter la logique pour rafraîchir la liste des articles ou autre
        } catch (error) {
            console.error("Erreur lors de la publication de l'article:", error);
            // Ici tu peux ajouter la logique pour gérer l'erreur
        }
    };



    // Assurez-vous d'avoir une logique pour gérer la création d'un article et la récupération de tous les prompts

    return (
        <Container fluid className='dashboard'>
            <Row>
                <Col md={12}>
                    <h1>Tableau de bord de l'administrateur</h1>

                    {/* Tableau des utilisateurs */}
                    <h2>Utilisateurs</h2>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>Rôle</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user => (
                                <tr key={user.id}>
                                    <td>{user.email}</td>
                                    <td>{user.role}</td>
                                    <td>
                                        <Button variant="primary" onClick={() => handleShowModal(user)} className="dashboard-action-button">
                                            <FontAwesomeIcon icon={faEdit} /> Modifier
                                        </Button>
                                        <Button variant="danger" onClick={() => handleBanUser(user.id)} className="dashboard-action-button">
                                            <FontAwesomeIcon icon={faBan} /> Bannir
                                        </Button>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {/* Bouton pour ajouter un article */}
                    <Button onClick={() => setShowModal(true)}>
                        <FontAwesomeIcon icon={faPlus} /> Ajouter un article
                    </Button>
                </Col>
            </Row>

            {/* Modal pour ajouter/modifier un article ou un utilisateur */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedUser ? "Modifier l'utilisateur" : "Nouvel article"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedUser ? (
                        // Formulaire pour modifier un utilisateur
                        <Form>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" defaultValue={selectedUser.email} readOnly />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Rôle</Form.Label>
                                <Form.Select onChange={(e) => handleRoleChange(selectedUser.id, e.target.value)}>
                                    <option value="user">Utilisateur</option>
                                    <option value="moderator">Modérateur</option>
                                    <option value="admin">Admin</option>
                                </Form.Select>
                            </Form.Group>
                            <Button variant="primary" onClick={() => handleRoleChange(selectedUser.id, selectedUser.role)}>
                                Sauvegarder les changements
                            </Button>
                        </Form>
                    ) : (
                        // Formulaire pour ajouter un article
                        <Form>
                            <Form.Group>
                                <Form.Label>Titre de l'article</Form.Label>
                                <ReactQuill
                                    value={articleTitle}
                                    onChange={setArticleTitle}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Contenu de l'article</Form.Label>
                                <ReactQuill
                                    value={articleContent}
                                    onChange={setArticleContent}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Image de l'article</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={e => setArticleImage(e.target.files[0])}
                                />
                            </Form.Group>
                            <Button variant="primary" onClick={handleSaveArticle}>
                                Publier l'article
                            </Button>
                        </Form>
                    )}
                </Modal.Body>
            </Modal>
        </Container >
    );
}

export default Dashboard;