import React, { useState, useEffect, useRef } from 'react';
import './ChatBox.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { apiFunction } from './apiFunction';
import { db } from '../firebase-config';
import { collection, addDoc, query, orderBy, onSnapshot, where } from 'firebase/firestore'; // Ajoutez l'import where
import { auth } from '../firebase-config';

function ChatBox() {
    const [userMessage, setUserMessage] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const isMounted = useRef(true);
    const location = useLocation();
    const { promptContent } = location.state || {};
    const [isSending, setIsSending] = useState(false); // Nouvel état pour suivre l'envoi
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        isMounted.current = true;
    
        // Récupérez l'ID de l'utilisateur actuel
        const userId = auth.currentUser ? auth.currentUser.uid : null;
    
        // Créez la requête Firestore pour récupérer uniquement les messages de l'utilisateur actuel
        const q = query(
            collection(db, "messages"),
            where("userId", "==", userId), // Filtrez par userId
            orderBy("timestamp", "asc")
        );
    
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const messages = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    ...data,
                    id: doc.id,
                    timestamp: data.timestamp.toDate(),
                };
            });
            if (isMounted.current) {
                setChatMessages(messages);
            }
        });
    
        return () => {
            unsubscribe();
            isMounted.current = false;
        };
    }, []);
    
    
    



    useEffect(() => {
        if (promptContent) {
            setUserMessage(promptContent);
        }
    }, [promptContent]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [chatMessages]);

    const handleUserMessageSubmit = async (event) => {
        event.preventDefault();
        if (userMessage.trim() && !isSending) {
            setIsSending(true); // Démarrer l'envoi
            setIsLoading(true); // Définir l'état de chargement sur true
            await sendMessageToChatbox(userMessage);
            setUserMessage('');
            setIsSending(false); // Terminer l'envoi
            setIsLoading(false); // Définir l'état de chargement sur false une fois terminé
        }
    };

    const sendMessageToChatbox = async (message) => {
        const messageData = {
            sender: 'user',
            text: message,
            timestamp: new Date(),
            userId: auth.currentUser.uid // Ajouter l'ID de l'utilisateur au message
        };
    
        const userMessageRef = await addDoc(collection(db, "messages"), messageData);
    
        try {
            const promptId = userMessageRef.id;
            const aiResponse = await apiFunction(promptId, message);
    
            if (isMounted.current) {
                const responseData = {
                    sender: 'ai',
                    text: aiResponse,
                    timestamp: new Date(),
                    userId: auth.currentUser.uid // Utiliser l'ID de l'utilisateur actuel pour les réponses de l'IA
                };
    
                await addDoc(collection(db, "messages"), responseData);
            }
        } catch (error) {
            if (isMounted.current) {
                console.error('Error while sending the message:', error);
            }
        }
    };
    

    const handleUserMessageChange = (event) => {
        setUserMessage(event.target.value);
    };

    // Utilisé pour le débogage, peut être retiré une fois que tout fonctionne correctement
    console.log(chatMessages);

    return (
        <div className="chat-box mt-5">
            <div className="messages">
                {chatMessages.map((msg) => (
                    <div key={msg.id} className={`message ${msg.sender}`}>
                        <span>{msg.sender === 'user' ? 'Vous' : 'IA'}:</span>
                        <p>{msg.text}</p>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <form onSubmit={handleUserMessageSubmit} className="message-form">
                <input
                    type="text"
                    value={userMessage}
                    onChange={handleUserMessageChange}
                    placeholder="Tapez votre message ici..."
                    disabled={isSending}
                />
                <button type="submit" disabled={isSending || isLoading}>
                    {isLoading ? 'Envoi en cours...' : (isSending ? 'Envoi...' : <FontAwesomeIcon icon={faPaperPlane} />)}
                </button>

            </form>
        </div>
    );
}

export default ChatBox;
