import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './PrivacyPolicy.css'; // Assurez-vous que le chemin est correct

const PrivacyPolicy = () => {
    return (
        <Container className="my-5 politique-marge-navbar">
            <Row>
                <Col xs={12}>
                    <h1>Politique de confidentialité</h1>
                    <p className="mt-4">Dernière mise à jour : 15/03/2024</p>
                    
                    <section className="mt-4">
                        <h2>Introduction</h2>
                        <p>Nous prenons la confidentialité des données très au sérieux chez Div Web Association. Cette politique explique comment nous collectons, utilisons, partageons et protégeons vos informations personnelles.</p>
                    </section>
                    
                    <section className="mt-4">
                        <h2>Collecte des données</h2>
                        <p>Les types de données que nous collectons incluent, mais ne sont pas limités à :</p>
                        <ul>
                            <li>Informations d'identification personnelle (nom, email, numéro de téléphone, etc.)</li>
                            <li>Données de connexion (adresses IP, logs de navigation, etc.)</li>
                        </ul>
                    </section>
                    
                    <section className="mt-4">
                        <h2>Utilisation des données</h2>
                        <p>Nous utilisons vos données pour :</p>
                        <ul>
                            <li>Fournir, opérer et maintenir notre service</li>
                            <li>Améliorer, personnaliser et élargir notre service</li>
                        </ul>
                    </section>
                    
                    <section className="mt-4">
                        <h2>Partage des données</h2>
                        <p>Nous ne partageons vos données personnelles avec des tiers que dans les cas suivants :</p>
                        <ul>
                            <li>Avec votre consentement</li>
                            <li>Pour se conformer à la loi</li>
                        </ul>
                    </section>
                    
                    <section className="mt-4">
                        <h2>Vos droits</h2>
                        <p>En tant qu'utilisateur, vous avez le droit de :</p>
                        <ul>
                            <li>Accéder à vos données personnelles</li>
                            <li>Demander la correction de vos données personnelles</li>
                        </ul>
                    </section>
                    
                    <section className="mt-4">
                        <h2>Sécurité des données</h2>
                        <p>Nous prenons des mesures pour protéger vos données contre l'accès non autorisé, la divulgation ou la destruction.</p>
                    </section>
                    
                    <section className="mt-4">
                        <h2>Modifications de la politique</h2>
                        <p>Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de tout changement en publiant la nouvelle politique sur cette page.</p>
                    </section>
                    
                    <section className="mt-4">
                        <h2>Contactez-nous</h2>
                        <p>Si vous avez des questions sur cette politique, veuillez nous contacter à info-contact@divweb.fr.</p>
                    </section>
                </Col>
            </Row>
        </Container>
    );
};

export default PrivacyPolicy;
