import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Button, Row, Col, Alert, Form, Modal } from 'react-bootstrap';
import { useUser } from '../context/UserContext';
import { db } from '../firebase-config';
import { collection, addDoc } from 'firebase/firestore';
import './Game.css';
import Leaderboard from '../components/Leaderboard';

const Game = () => {
  const [sequence, setSequence] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [level, setLevel] = useState(1);
  const [gameState, setGameState] = useState('idle');
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [showSequence, setShowSequence] = useState(false);
  const [timer, setTimer] = useState(10);
  const { currentUser } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [pseudo, setPseudo] = useState('');
  const inputRef = useRef(null);
  const isMounted = useRef(true);
  const [isTimerStarted, setIsTimerStarted] = useState(false);

  const recordScore = useCallback(async (score) => {
    if (currentUser || pseudo) {
      await addDoc(collection(db, "Scores"), {
        userId: currentUser ? currentUser.uid : null,
        pseudo: pseudo,
        score,
        date: new Date(),
      });
    }
  }, [currentUser, pseudo]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let intervalId = null;
    if (gameState === 'input' && !showSequence && isTimerStarted) {
      intervalId = setInterval(() => {
        setTimer((t) => {
          if (t === 1) {
            clearInterval(intervalId);
            if (gameState === 'input') {
              setGameState('lost');
              setShowModal(true); // Ouvrir la modal lorsque le timer arrive à 0
              recordScore(level - 1);
            }
          }
          return t - 1;
        });
      }, 1000);
    }
  
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [gameState, showSequence, recordScore, level, isTimerStarted]);
  
  

  const startGame = () => {
    setIsGameStarted(true);
    setGameState('playing');
    setLevel(1);
    setUserInput('');
    setSequence([]);
    setTimer(10);
    setIsTimerStarted(false);
    addNumberToSequence();
  };

  const addNumberToSequence = () => {
    const newNumber = Math.floor(Math.random() * 10);
    setSequence((prevSequence) => [...prevSequence, newNumber]);
    setShowSequence(true);

    setTimeout(() => {
      if (isMounted.current) {
        setShowSequence(false);
        setGameState('input');
        setTimer(10);
        setIsTimerStarted(true);
        inputRef.current && inputRef.current.focus();
      }
    }, 3000 + (level > 10 ? 1000 : 0));
  };

  const handleInputChange = (e) => {
    if (gameState === 'input' && timer > 0 && isTimerStarted) {
      setUserInput(e.target.value);
    }
  };

const handleSubmit = async () => {
  if (!showSequence) {
    const userSequence = userInput.split('').map(Number);

    if (userSequence.length === sequence.length && userSequence.every((num, index) => num === sequence[index])) {
      if (level === 20) {
        setGameState('won');
        await recordScore(level);
        setShowModal(true);
      } else {
        setLevel((l) => l + 1);
        setUserInput('');
        addNumberToSequence();
      }
    } else {
      setGameState('lost');
      await recordScore(level - 1);
      setShowModal(true);
    }
  }
};

  const handleModalClose = useCallback(async () => {
    setShowModal(false);
    await recordScore(level - 1);
  }, [level, recordScore]);

  const handlePaste = (e) => {
    e.preventDefault();
  };

  const handleCopy = (e) => {
    e.preventDefault();
  };



  return (
    <Container className="mt-5 gamecontainer">
    <h1>Le Défi des Chiffres de l'IA</h1>
    <Row>
      <Col>
        <Leaderboard />
      </Col>
      <Col>
        {!isGameStarted && (
          <Button variant="primary" onClick={startGame}>Commencer le jeu</Button>
        )}
        {isGameStarted && (
          <>
            <Row className="mb-3">
              <Col>
                <Alert variant={gameState === 'playing' || gameState === 'input' ? "info" : gameState === 'won' ? "success" : "danger"}>
                  {gameState === 'playing' || gameState === 'input' ? `Niveau : ${level}` : gameState === 'won' ? "Félicitations ! Vous avez gagné !" : "Dommage, vous avez perdu. Essayez encore !"}
                </Alert>
              </Col>
            </Row>
            <Row className="mb-3 justify-content-center">
              {showSequence &&
                sequence.slice(level > 10 ? -1 : -level).map((num, index) => (
                  <Col key={index} xs={2} className="sequence-box">{num}</Col>
                ))
              }
              {!showSequence && gameState === 'input' &&
                <Col xs={2} className="sequence-box timer-box">{timer}</Col>
              }
            </Row>
            {gameState === 'input' && (
              <Row className="mb-3">
                <Col>
                  <Form.Control
                    type="text"
                    value={userInput}
                    onChange={handleInputChange}
                    onPaste={handlePaste}
                    onCopy={handleCopy}
                    ref={inputRef}
                    placeholder="Entrez la séquence"
                    disabled={timer === 0 || !isTimerStarted}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Button onClick={handleSubmit} disabled={gameState !== 'input' || timer === 0 || !isTimerStarted}>Soumettre</Button>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
    <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Entrez votre pseudo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          value={pseudo}
          onChange={(e) => setPseudo(e.target.value)}
          placeholder="Votre pseudo"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Valider
        </Button>
      </Modal.Footer>
    </Modal>
  </Container>
);
};

export default Game;