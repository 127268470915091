import React from 'react';
import './App.css';
import MyNavbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ManagePrompts from './pages/ManagePrompts';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import { UserProvider } from './context/UserContext';
import ArticleDetail from './components/ArticleDetail';
import EditArticle from './components/EditArticle';
import ChatBox from './components/ChatBox';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUsePage from './pages/TermsOfUsePage'; // Importez la page TermsOfUsePage
import Game from './pages/Game';
import UserProfile from './pages/UserProfile'; // Importez le nouveau composant UserProfile
import ProjectsPage from './pages/ProjectsPage'; // Importez le nouveau composant ProjectsPage
import CreateProjectForm from './components/CreateProjectForm'; // Importez le nouveau composant CreateProjectForm
import ProjectBoard from './components/ProjectBoard'; // Importez le nouveau composant ProjectBoard

function App() {
  return (
    <UserProvider>
      <Router>
        <MyNavbar />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/article/:articleId" element={<ArticleDetail />} />
            <Route path="/edit-article/:articleId" element={<EditArticle />} />
            <Route path="/manage-prompts" element={<ManagePrompts />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/project/:projectId" element={<ProjectBoard />} />
            <Route path="/create-project" element={<CreateProjectForm />} />
            <Route path="/chatbox/:promptId" element={<ChatBox />} />
            <Route path="/profile" element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            } />
            <Route path="/dashboard" element={
              <ProtectedRoute requiredRole="admin">
                <Dashboard />
              </ProtectedRoute>
            } />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfUsePage />} />
            <Route path="/game" element={<Game />} />

          </Routes>
        </div>
        <Footer />
      </Router>
    </UserProvider>
  );
}

export default App;
